






















































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import LocalStorage from "@/core/utils/LocalStorage";
import { Presentation, Recording } from "@/core/models/";
import VideoRecorderWithPresentation from "@/components/common/VideoRecorderWithPresentations.vue";
import { AudioRecorder, RecordList, PresentationSelect } from "./components";
import VolumeTester from "./components/volume-tester";
import { createRecorderTour, Tour } from "@/core/utils/siteTour";

@Component({
  components: {
    RecordList,
    VolumeTester,
    AudioRecorder,
    PresentationSelect,
    VideoRecorderWithPresentation,
  },
})
export default class Recorder extends Vue {
  @Getter("sessions/sessionsCount") sessionsCount!: number;
  @Getter("profile/showTutorial") showTutorial!: boolean;
  @Getter("profile/tutorialStep") tutorialStep!: number;
  @Action("presentations/getOne")
  getOne!: (id: number) => Promise<Presentation>;

  canSelect = false;
  viewMode = "slide";
  isRecording = false;
  recordingType: "audio" | "video" = "video";
  records: Recording[] = [];
  selectedPresentation: Presentation | null = null;
  recordingCreated(record: Recording) {
    this.records = [record, ...this.records];
  }

  tipDialog = false;
  showVolume = false;
  mounted() {
    this.showVolume = true;
    const show = LocalStorage.getShouldShowRecordingTip();
    if (show && this.$route.query.tour !== "true")
      this.$nextTick(() => (this.tipDialog = true));
    document.documentElement.classList.add("no-scroll");
  }

  tour: Tour | null = null;
  startTour() {
    this.tour = createRecorderTour();
    this.tour.start();
  }
  @Watch("$route", { immediate: true })
  async routeChanged() {
    const { tour, presentation } = this.$route.query;

    // check tour
    const id = Number(presentation);
    if (!isNaN(id)) this.selectedPresentation = await this.getOne(id);

    // check pres
    if (tour === "true" && this.showTutorial && this.tutorialStep === 3)
      this.startTour();
  }
  @Watch("selectedPresentation")
  presChanged() {
    if (!this.tour || this.tour.current !== 3) return;
    this.tour.next();
  }
  @Watch("records")
  recordsChanged(value: Recording[], prev: Recording[]) {
    if (!this.tour || value.length <= prev.length) return;
    if (this.tour.current === 9 || this.tour.current === 15) this.tour.next();
  }
  dialogToggled(v: boolean) {
    if (!this.tour) return;
    if (v && (this.tour.current === 10 || this.tour.current === 16))
      this.tour.next();
    if (!v && this.tour.current === 11) this.tour.next();
  }
  @Watch("recordingType")
  typeChanged(value: string) {
    if (!this.tour || this.tour.current !== 12) return;
    if (value === "video") this.tour.next();
  }
  @Watch("sessionsCount")
  countChanged(value: number, prev: number) {
    if (!this.tour || value <= prev || this.tour.current !== 17) return;
    this.tour.jump(18);
  }
}
